// See https://github.com/mui-org/material-ui/blob/master/packages/mui-utils/src/useId.ts
import { useState, useEffect } from 'react'

let globalId = 0
export default function useId(idOverride?: string): string | undefined {
  const [defaultId, setDefaultId] = useState(idOverride)
  const id = idOverride || defaultId
  useEffect(() => {
    if (defaultId == null) {
      // Fallback to this default id when possible.
      // Use the incrementing value for client-side rendering only.
      // We can't use it server-side.
      // If you want to use random values please consider the Birthday Problem: https://en.wikipedia.org/wiki/Birthday_problem
      globalId += 1
      setDefaultId(`nfd-${globalId}`)
    }
  }, [defaultId])
  return id
}
