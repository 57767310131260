import { isValidIpfsUrl } from 'helpers/ipfs'

export function isAlgoXyzUrlValid(url?: string) {
  if (url === undefined) {
    return true
  }

  if (url === '') {
    return true
  }

  const isIpfs = url.startsWith('ipfs://')
  if (isIpfs) {
    return isValidIpfsUrl(url)
  }

  const match = url.match(/^(https?):\/\/(-\.)?([^\s\/?\.#]+\.?)+(\/[^\s]*)?$/gm)

  return match !== null
}
