import { nfdUpdateAll, type UpdatePartialRequestBody } from 'api/api-client'
import { MutationOptions, usePostTransaction } from './usePostTransaction'

type PutUpdatePropertiesParams = {
  name: string
  body: UpdatePartialRequestBody
}

type PutUpdatePropertiesOptions = MutationOptions<PutUpdatePropertiesParams>

export function usePutUpdateProperties(options: PutUpdatePropertiesOptions = {}) {
  return usePostTransaction({
    mutationFn: ({ name, body }) => nfdUpdateAll(name, body),
    ...options
  })
}
