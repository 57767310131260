import { RadioGroup } from '@headlessui/react'
import { tabs } from 'components/DetailView/Assets/Assets.constants'
import { AssetSubNav } from 'components/DetailView/Assets/Assets.types'
import { classNames } from 'helpers/utilities'

interface GalleryToggleProps {
  onChange: (galleryType: AssetSubNav) => void
  value: AssetSubNav | null
}

export default function GalleryToggle({ onChange, value }: GalleryToggleProps) {
  return (
    <div className="inline-flex shadow-sm rounded-md divide-x divide-brand-600 w-full sm:w-auto">
      <div className="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-brand-600 w-full">
        <RadioGroup className="flex flex-nowrap flex-1" value={value} onChange={onChange}>
          <RadioGroup.Label className="sr-only">Choose a view</RadioGroup.Label>
          <div className="flex w-full">
            {tabs.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option.value}
                className={({ active, checked }) =>
                  classNames(
                    active ? 'ring-2 ring-offset-2 ring-brand-500' : '',
                    checked
                      ? 'bg-brand-500 border-transparent text-white hover:bg-brand-600'
                      : 'bg-white border-gray-300 text-gray-700 hover:bg-gray-50 dark:bg-gray-750 dark:border-transparent dark:border-l-black/20 dark:group-first:border-l-transparent dark:text-gray-300 dark:hover:bg-gray-700',
                    'flex-1 relative inline-flex items-center justify-center px-4 py-2 border text-sm font-medium group rounded-none first:rounded-l-md last:rounded-r-md focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-brand-500 dark:focus:ring-offset-gray-850'
                  )
                }
                data-cy={`gallery-toggle-${option.value}`}
              >
                {({ checked }) => (
                  <RadioGroup.Label className="inline-flex items-center">
                    <option.icon
                      className={classNames(
                        checked
                          ? 'text-white'
                          : 'text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-400',
                        'hidden xl:inline -ml-1 mr-2 h-5 w-5'
                      )}
                      aria-hidden="true"
                    />
                    <span className="whitespace-nowrap">{option.label}</span>
                  </RadioGroup.Label>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  )
}
