// See https://github.com/vercel/next.js/discussions/32231#discussioncomment-1766746
import { useEffect, useRef } from 'react'
import Router from 'next/router'

export default function useUnsavedChanges(shouldWarn: boolean) {
  const message = 'You have unsaved changes. Are you sure that you want to leave?'

  const lastHistoryState = useRef(global.history?.state)

  useEffect(() => {
    const storeLastHistoryState = () => {
      lastHistoryState.current = history.state
    }

    Router.events.on('routeChangeComplete', storeLastHistoryState)

    return () => {
      Router.events.off('routeChangeComplete', storeLastHistoryState)
    }
  }, [])

  useEffect(() => {
    let isWarned = false

    const routeChangeStart = (url: string) => {
      if (Router.asPath !== url && shouldWarn && !isWarned) {
        isWarned = true

        if (window.confirm(message)) {
          Router.push(url)
        } else {
          isWarned = false

          Router.events.emit('routeChangeError')

          const state = lastHistoryState.current

          if (state != null && history.state != null && state.idx !== history.state.idx) {
            history.go(state.idx < history.state.idx ? -1 : 1)
          }

          // eslint-disable-next-line no-throw-literal
          throw 'Abort route change. Please ignore this error.'
        }
      }
    }

    const beforeUnload = (e: BeforeUnloadEvent) => {
      if (shouldWarn && !isWarned) {
        const event = e || window.event
        event.returnValue = message
        return message
      }

      return null
    }

    Router.events.on('routeChangeStart', routeChangeStart)
    window.addEventListener('beforeunload', beforeUnload)

    return () => {
      Router.events.off('routeChangeStart', routeChangeStart)
      window.removeEventListener('beforeunload', beforeUnload)
    }
  }, [message, shouldWarn])
}
