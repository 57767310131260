import { Switch } from '@headlessui/react'
import { classNames } from 'helpers/utilities'
import type { ReactNode } from 'react'

interface ToggleProps {
  checked: boolean
  onChange: (checked: boolean) => void
  label?: ReactNode
  labelOn?: 'left' | 'right'
  className?: string
  disabled?: boolean
}

export default function Toggle({
  checked,
  onChange,
  label,
  labelOn = 'right',
  className = '',
  disabled = false
}: ToggleProps) {
  return (
    <Switch.Group
      as="div"
      className={classNames(disabled ? 'opacity-30' : '', 'flex items-center', className)}
    >
      {label && labelOn === 'left' && (
        <Switch.Label as="span" className="mr-3">
          <span className="text-sm font-medium whitespace-nowrap text-gray-900 dark:text-gray-400">
            {label}
          </span>
        </Switch.Label>
      )}

      <Switch
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        className={classNames(
          checked ? 'bg-brand-500' : 'bg-gray-200 dark:bg-gray-100/10',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 dark:focus:ring-offset-gray-900'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            checked
              ? 'bg-white dark:bg-gray-200 translate-x-5'
              : 'bg-white dark:bg-gray-400 translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>

      {label && labelOn === 'right' && (
        <Switch.Label as="span" className="ml-3">
          <span className="text-sm font-medium whitespace-nowrap text-gray-900 dark:text-gray-400">
            {label}
          </span>
        </Switch.Label>
      )}
    </Switch.Group>
  )
}
