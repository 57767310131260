import { HiBadgeCheck } from 'react-icons/hi'
import Tooltip from 'components/Tooltip'

export default function VerifiedBadge({ isVerified }: { isVerified: boolean }) {
  if (!isVerified) {
    return null
  }

  return (
    <div className="absolute inset-y-0 right-0 px-3 flex items-center">
      <Tooltip text="Verified" direction="left">
        <div>
          <HiBadgeCheck className="h-5 w-5 text-gray-500 dark:text-brand-500" aria-hidden="true" />
        </div>
      </Tooltip>
    </div>
  )
}
