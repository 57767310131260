import { IconClassNameMap } from './CryptoAddress.types'

export const iconClassName: IconClassNameMap = {
  ada: '',
  algo: 'no-dark-adjust dark:invert dark:opacity-20',
  atom: '',
  avax: '',
  btc: '',
  egld: 'no-dark-adjust dark:invert dark:opacity-20',
  eth: 'no-dark-adjust dark:invert dark:opacity-75',
  hbar: 'no-dark-adjust dark:invert dark:opacity-20',
  matic: '',
  near: 'no-dark-adjust dark:invert dark:opacity-20',
  sol: '',
  vet: '',
  xrp: 'no-dark-adjust dark:invert dark:opacity-20',
  xtz: '',
  zil: ''
}
