import { classNames } from 'helpers/utilities'

type SectionHeaderProps = {
  title: string
  description: string | React.ReactNode
  hideTitleMobile?: boolean
}

export default function SectionHeader({
  title,
  description,
  hideTitleMobile = false
}: SectionHeaderProps) {
  return (
    <div>
      <h2
        className={classNames(
          hideTitleMobile ? 'hidden sm:block' : '',
          'text-lg font-medium leading-6 text-gray-900 dark:text-gray-100'
        )}
      >
        {title}
      </h2>
      <p
        className={classNames(
          hideTitleMobile
            ? 'text-gray-700 pt-1 pb-2 sm:text-gray-500 sm:mt-1 sm:text-sm dark:text-gray-400'
            : 'mt-1 text-sm text-gray-500'
        )}
      >
        {description}
      </p>
    </div>
  )
}
