import dynamic from 'next/dynamic'
import * as React from 'react'
import { NfdRecord } from '@/api/api-client'
import AssetMedia from '@/components/AssetMedia'
import VerifiedBadge from '@/components/VerifiedBadge'
import { getAvatarURL, isAvatarVerified } from '@/helpers/avatar'
import { getBannerURL, isBannerVerified } from '@/helpers/banner'
import galleryImageLoader from '@/helpers/galleryImageLoader'
import { clsxMerge } from '@/helpers/utilities'
import AvatarBannerMenu from './AvatarBannerMenu'

const AvatarBannerModal = dynamic(() => import('./AvatarBannerModal'))

export type Field = 'avatar' | 'banner'

interface ManageAvatarBannerProps {
  nfd: NfdRecord
}

export function ManageAvatarBanner({ nfd }: ManageAvatarBannerProps) {
  const [selectedField, setSelectedField] = React.useState<Field | null>(null)

  return (
    <>
      <div className="grid grid-cols-1 gap-y-6 gap-x-8 xl:gap-x-16 lg:grid-cols-3">
        <div>
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
            Avatar
          </label>
          <div className="mt-1 flex flex-col gap-4 lg:items-center">
            <div
              className={clsxMerge(
                'flex items-center justify-center rounded-full',
                isAvatarVerified(nfd)
                  ? 'bg-gradient-to-br from-violet-600 to-pink-500 h-[11.25rem] w-[11.25rem] lg:h-[9.25rem] lg:w-[9.25rem] xl:h-[13.25rem] xl:w-[13.25rem]'
                  : 'h-40 w-40 lg:h-32 lg:w-32 xl:h-48 xl:w-48 2xl:my-1'
              )}
            >
              <div
                className={clsxMerge(
                  'relative inline-block h-40 w-40 rounded-full overflow-hidden ring-white bg-gray-100 dark:ring-gray-800 dark:bg-gray-800 lg:block lg:h-32 lg:w-32 xl:h-48 xl:w-48',
                  isAvatarVerified(nfd) ? 'ring-4' : 'ring-0'
                )}
              >
                <AssetMedia
                  src={getAvatarURL(nfd)}
                  alt={`${nfd.name} avatar`}
                  className="object-cover w-full h-full"
                  loader={galleryImageLoader}
                  sizes="(max-width: 1024px) 160px, 300px"
                  fill
                />
              </div>
            </div>
            <div>
              <AvatarBannerMenu nfd={nfd} field="avatar" setField={setSelectedField} />
            </div>
          </div>
        </div>

        <div className="lg:col-span-2">
          <div className="max-w-xl lg:max-w-none">
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-400">
              Banner
            </label>
            <div className="mt-1 flex flex-col gap-4 lg:items-center">
              <div
                className={clsxMerge(
                  'relative w-full aspect-[3/1] bg-gray-100 dark:bg-gray-800',
                  isBannerVerified(nfd) && 'overflow-hidden rounded-br-xl'
                )}
              >
                <AssetMedia
                  src={getBannerURL(nfd)}
                  alt={`${nfd.name} banner`}
                  className="object-cover w-full h-full"
                  loader={galleryImageLoader}
                  sizes="(max-width: 640px) 100vw, 600px"
                  fill
                />
                <div
                  className={clsxMerge(
                    'absolute bottom-0 right-0 translate-x-1/2 translate-y-1/2 scale-[60%] xs:scale-75 lg:scale-[60%] xl:scale-75',
                    !isBannerVerified(nfd) && 'hidden'
                  )}
                >
                  <VerifiedBadge small />
                </div>
              </div>
              <div>
                <AvatarBannerMenu nfd={nfd} field="banner" setField={setSelectedField} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <AvatarBannerModal nfd={nfd} field={selectedField} setField={setSelectedField} />
    </>
  )
}
