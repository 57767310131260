export enum AssetSubNav {
  Vault = 'vault',
  Owned = 'owned',
  ForSale = 'for-sale',
  Creations = 'creations'
}

export enum AssetTypeFilter {
  NFTs = 'nfts',
  Tokens = 'tokens',
  NFDs = 'nfds'
}

export type FilterProps = {
  searchQuery: string | null
  accounts: string[] | null
  typeFilter: Array<AssetTypeFilter> | null
}
