import { AiFillBank } from 'react-icons/ai'
import { HiSparkles, HiTag } from 'react-icons/hi'
import { RiFlaskLine } from 'react-icons/ri'
import { AssetSubNav } from './Assets.types'

export const tabs = [
  {
    value: AssetSubNav.Vault,
    label: 'Vault',
    icon: AiFillBank
  },
  {
    value: AssetSubNav.Owned,
    label: 'Owned',
    icon: HiSparkles
  },
  {
    value: AssetSubNav.ForSale,
    label: 'For Sale',
    icon: HiTag
  },
  {
    value: AssetSubNav.Creations,
    label: 'Created',
    icon: RiFlaskLine
  }
]
