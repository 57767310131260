import { classNames } from 'helpers/utilities'
import { hexagonClipPath } from './Badges.constants'
import type { Badge } from './Badges.types'

type BadgeIconProps = {
  badge: Badge
  className?: string
}

export default function BadgeIcon({ badge, className }: BadgeIconProps) {
  return (
    <span
      className={classNames(
        'inline-flex items-center justify-center w-12 h-12 md:w-10 md:h-10',
        badge.bgClassName,
        className ? className : ''
      )}
      style={{
        clipPath: hexagonClipPath
      }}
    >
      <badge.icon className="text-white text-xl md:text-lg" />
    </span>
  )
}
