import { isValidAlgoAddress } from './utilities'

export const addAlgoProtocol = (str: string) => {
  return str
    .split(' ')
    .map((word) => {
      if (isValidAlgoAddress(word)) {
        return `algo:${word}`
      }

      return word
    })
    .join(' ')
}

/**
 * The linkify-it library can recognize URLs with or without the protocol,
 * e.g., https://example.com or example.com.
 *
 * This decorator function will try to keep the URL as readable as possible
 * without exceeding the max allowed characters. Truncation happens in the
 * middle of the URL, while attempting to display the full hostname and the
 * very end of the remainder. The protocol is dropped to show only what's
 * necessary. (See https://ux.stackexchange.com/a/40342)
 *
 * If that fails, it will simply split the provided link in half.
 *
 * @param link The link to be decorated (truncated only if necessary)
 * @param maxLength The max length of the link (default: 40)
 * @returns e.g., example.com or example.com...only/the/end-of-link
 */
export const formatLink = (link: string, maxLength = 40) => {
  try {
    // Add `https://` if missing protocol
    const hasProtocol = link.startsWith('http://') || link.startsWith('https://')
    const hasNeutralProtocol = link.startsWith('//')
    const withProtocol = hasProtocol
      ? link
      : hasNeutralProtocol
      ? `https:${link}`
      : `https://${link}`

    // If this fails (invalid URL), jump to catch block
    const { protocol, hostname } = new URL(withProtocol)

    // Remove `http(s)://` now that we have the hostname
    let trimmedUrl = link.split(`${protocol}//`)[1]

    // Remove trailing slash if it exists
    if (trimmedUrl.endsWith('/')) {
      trimmedUrl = trimmedUrl.slice(0, -1)
    }

    if (trimmedUrl.length <= maxLength) {
      // No truncation needed
      return trimmedUrl
    }

    // Truncate the remainder of the link from the front, leaving the end
    const truncatedRemainder = trimmedUrl
      .split(`${hostname}`)[1]
      .slice((maxLength - hostname.length) * -1)

    return [hostname, truncatedRemainder].join('...')
  } catch {
    // Unable to isolate hostname
    if (link.length <= maxLength) {
      // No truncation needed
      return link
    } else {
      // Basic truncation, split in half and join with ellipsis
      const firstHalf = link.slice(0, Math.floor(maxLength / 2))
      const secondHalf = link.slice(Math.floor(maxLength / 2) * -1)

      return [firstHalf, secondHalf].join('...')
    }
  }
}
