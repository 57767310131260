import { HiExclamationCircle, HiX } from 'react-icons/hi'
import { BiLinkExternal } from 'react-icons/bi'
import Button from 'components/Button'
import GalleryToggle from 'components/GalleryToggle'
import SectionHeader from 'components/manage/SectionHeader'
import useAlgoXyzAddressComponent from './AlgoXyzUrl.hooks'
import { classNames, isValidSegment, trimExtension } from 'helpers/utilities'
import type { NfdRecord } from 'api/api-client'

export type AlgoXyzUrlProps = {
  nfd: NfdRecord
  url: string
  handleSetUrl: (url: string) => void
}

export default function AlgoXyzAddress({ nfd, url, handleSetUrl }: AlgoXyzUrlProps) {
  const { errorMsg, viewToggle, handleClear, handleSetGallery, isValid } =
    useAlgoXyzAddressComponent({
      nfd,
      url,
      handleSetUrl
    })

  const isSegment = isValidSegment(nfd.name)
  const redirectUrl = isSegment
    ? `https://algo.xyz/${trimExtension(nfd.name)}`
    : `https://${nfd.name}.xyz`

  return (
    <div className="space-y-6 md:min-h-[12rem]">
      <SectionHeader
        title="Algo.xyz"
        description={
          <>
            Set the destination address for{' '}
            <a
              href={redirectUrl}
              className="inline-flex items-center font-semibold text-gray-600 hover:text-gray-900 group dark:text-brand-500 dark:hover:text-brand-600"
              target="_blank"
              rel="noreferrer"
            >
              <span className="underline dark:no-underline">{redirectUrl}</span>{' '}
              <BiLinkExternal className="ml-1 h-4 w-4 text-gray-400 group-hover:text-gray-500 dark:text-gray-500" />
            </a>
          </>
        }
        hideTitleMobile
      />
      <div>
        <label htmlFor="url" className="block text-sm font-medium text-gray-700 dark:text-gray-400">
          Redirect to
        </label>
        <div className="mt-1 relative">
          <div className="flex justify-center">
            <div className="relative w-full rounded-md shadow-sm">
              <input
                type="text"
                name="url"
                id="url"
                data-cy="input-url"
                className={classNames(
                  isValid
                    ? 'border-gray-300 focus:ring-brand-500 focus:border-brand-500 dark:focus:border-brand-500 dark:focus:ring-brand-500'
                    : 'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 dark:focus:border-red-500 dark:focus:ring-red-500',
                  'block w-full pr-16 sm:pr-10 focus:outline-none sm:text-sm rounded-md dark:bg-gray-800 dark:text-gray-100 dark:border-transparent dark:caret-gray-400'
                )}
                value={url}
                onChange={(e) => handleSetUrl(e.target.value)}
                aria-invalid={!isValid}
                aria-describedby={
                  errorMsg !== '' ? 'url-error' : url === '' ? 'url-default' : undefined
                }
              />
              {errorMsg !== '' && (
                <div
                  className="absolute inset-y-0 right-0 pr-10 sm:pr-3 flex items-center pointer-events-none"
                  data-cy="error-icon"
                >
                  <HiExclamationCircle className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
              )}
              {url !== '' && (
                <button
                  onClick={handleClear}
                  className="absolute sm:hidden inset-y-0 right-0 px-3 flex items-center group"
                >
                  <HiX
                    className="h-5 w-5 text-gray-500 dark:group-hover:text-gray-400"
                    aria-hidden="true"
                  />
                </button>
              )}
            </div>
            <Button
              className="hidden sm:inline-block ml-2"
              onClick={handleClear}
              disabled={url === ''}
              data-cy="clear-url"
            >
              Clear
            </Button>
          </div>
        </div>
        {errorMsg !== '' && (
          <p
            className="mt-2 text-xs text-red-600 dark:text-red-500"
            id="url-error"
            data-cy="error-message"
          >
            {errorMsg}
          </p>
        )}
        <p className="mt-2 text-xs text-gray-600 dark:text-gray-500" id="url-default">
          If left blank, <strong className="font-semibold dark:text-gray-400">{redirectUrl}</strong>{' '}
          will redirect to your profile page (default)
        </p>
      </div>
      <div className="flex flex-col space-y-0 sm:items-start">
        <label
          htmlFor="url"
          className="block text-sm font-medium text-gray-700 mb-1 dark:text-gray-400"
        >
          Redirect to Assets View
        </label>
        <GalleryToggle value={viewToggle} onChange={(view) => handleSetGallery(view)} />
      </div>
    </div>
  )
}
