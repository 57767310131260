import Link from 'next/link'
import type { NfdRecord } from 'api/api-client'

interface VerificationHelpTextProps {
  nfd: NfdRecord
  field: string
  label: string
}

export default function VerificationHelpText({ nfd, field, label }: VerificationHelpTextProps) {
  const isVerified = !!nfd.properties?.verified?.[field]
  const isUserDefined = !!nfd.properties?.userDefined?.[field]

  if (isVerified) {
    return (
      <p className="mt-2 text-sm sm:text-xs text-gray-500">
        To change, first{' '}
        <Link
          href={`/manage/${nfd.name}?view=verification`}
          className="text-gray-900 font-medium underline dark:text-brand-500 dark:hover:text-brand-600 dark:no-underline"
        >
          unverify
        </Link>{' '}
        your {label}.
      </p>
    )
  }

  if (isUserDefined) {
    return (
      <p className="mt-2 text-sm sm:text-xs text-gray-500">
        You can{' '}
        <Link
          href={`/manage/${nfd.name}?view=verification`}
          className="text-gray-900 font-medium underline dark:text-brand-500 dark:hover:text-brand-600 dark:no-underline"
        >
          verify
        </Link>{' '}
        your {label} in the Verification section.
      </p>
    )
  }

  return null
}
