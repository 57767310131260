import { useMemo } from 'react'
import AlgoPrice from 'components/AlgoPrice'
import UsdPrice from 'components/UsdPrice'
import { convertUsdToMicroalgos, formatNumber, roundMicroalgos } from 'helpers/utilities'

interface PriceBreakdownInterface {
  algoUsd: number
  platformFee: number
  usdMinCost: number
  carryCost: number
}

type PriceBreakdownProps = (
  | {
      sellAmount: number
      unlockedSellPrice?: never
    }
  | {
      sellAmount?: never
      unlockedSellPrice: number
    }
) &
  PriceBreakdownInterface

export default function PriceBreakdown({
  algoUsd,
  usdMinCost,
  platformFee,
  carryCost,
  sellAmount,
  unlockedSellPrice
}: PriceBreakdownProps) {
  const totalPrice = useMemo(() => {
    if (unlockedSellPrice !== undefined) {
      const exactTotal = convertUsdToMicroalgos(unlockedSellPrice, algoUsd) + carryCost
      return roundMicroalgos(exactTotal, 3)
    }

    return sellAmount || 0
  }, [algoUsd, carryCost, sellAmount, unlockedSellPrice])

  const isInvalid = useMemo(() => {
    if (unlockedSellPrice !== undefined) {
      return unlockedSellPrice < usdMinCost || unlockedSellPrice > 1000000
    }
    return false
  }, [unlockedSellPrice, usdMinCost])

  const renderSalePrice = () => {
    if (unlockedSellPrice === undefined) {
      return null
    }

    const exactSalePrice = convertUsdToMicroalgos(unlockedSellPrice, algoUsd)
    const salePrice = roundMicroalgos(exactSalePrice, 3)

    return (
      <div className="grid grid-cols-2 gap-4 py-5 px-6">
        <dt className="text-sm font-medium text-gray-500">Sale price</dt>
        <dd className="inline-flex items-center flex-wrap text-sm text-gray-900 dark:text-gray-100">
          {isInvalid ? (
            <span>--</span>
          ) : (
            <>
              <AlgoPrice
                price={salePrice}
                options={{
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }}
              />
              <UsdPrice price={salePrice} className="text-right ml-1" />
            </>
          )}
        </dd>
      </div>
    )
  }

  const renderPlatformFee = () => {
    if (sellAmount === undefined) {
      return null
    }

    return (
      <div className="grid grid-cols-2 gap-4 py-5 px-6">
        <dt className="text-sm font-medium text-gray-500">Platform fee</dt>
        <dd className="inline-flex items-center flex-wrap text-sm text-gray-900 dark:text-gray-100">
          {isInvalid ? (
            <span>--</span>
          ) : (
            <>
              <AlgoPrice
                price={platformFee}
                options={{
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                }}
              />
              <UsdPrice price={platformFee} className="text-right ml-1 mr-2" />
            </>
          )}
        </dd>
      </div>
    )
  }

  const renderTotal = () => {
    return (
      <div className="grid grid-cols-2 gap-4 py-5 px-6 bg-gray-50 dark:bg-gray-850/50">
        <dt className="text-base font-semibold text-gray-900 dark:text-gray-100">Total</dt>
        <dd className="inline-flex items-center flex-wrap text-sm text-gray-900 dark:text-gray-100">
          {isInvalid ? (
            <span>--</span>
          ) : (
            <>
              <AlgoPrice
                price={totalPrice}
                options={{ minimumFractionDigits: 2 }}
                className="font-semibold text-base"
              />
              <UsdPrice price={totalPrice} className="text-right ml-1.5" />
            </>
          )}
        </dd>
      </div>
    )
  }

  return (
    <dl className="divide-y divide-gray-200 dark:divide-gray-750/75">
      <div className="grid grid-cols-2 gap-4 py-5 px-6">
        <dt className="text-sm font-medium text-gray-500">ALGO price</dt>
        <dd className="inline-flex items-center flex-wrap text-sm text-gray-900 dark:text-gray-100">
          ${' '}
          {formatNumber(algoUsd, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}
        </dd>
      </div>
      {renderSalePrice()}
      {renderPlatformFee()}
      <div className="grid grid-cols-2 gap-4 py-5 px-6">
        <dt className="text-sm font-medium text-gray-500">Carry cost</dt>
        <dd className="inline-flex items-center flex-wrap text-sm text-gray-900 dark:text-gray-100">
          <AlgoPrice price={carryCost} options={{ minimumFractionDigits: 2 }} />
          <UsdPrice price={carryCost} className="text-right ml-1" />
        </dd>
      </div>
      {renderTotal()}
    </dl>
  )
}
