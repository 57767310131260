import { classNames } from 'src/helpers/utilities'
import useId from 'hooks/useId'

const BASE_CLASSES = {
  label: 'block text-sm font-medium leading-5 text-gray-700 dark:text-gray-400',
  textarea:
    'mt-1 shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border border-gray-300 rounded-md bg-white dark:bg-gray-800 dark:text-gray-100 dark:border-transparent dark:focus:ring-brand-500 dark:focus:border-brand-500 dark:caret-gray-400'
}

interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  id?: string
  className?: string
  disabled?: boolean
  label?: string
  labelClassName?: string
  wrapClassName?: string
  rows?: number
  helpText?: string
}

export default function Textarea(props: TextareaProps) {
  const {
    id: idOverride,
    className,
    disabled,
    label,
    labelClassName,
    wrapClassName,
    rows = 3,
    helpText,
    ...rest
  } = props

  const id = useId(idOverride)

  const getLabelClassName = () => {
    return classNames(BASE_CLASSES.label, labelClassName as string)
  }

  const getTextareaClassName = () => {
    return classNames(BASE_CLASSES.textarea, className as string)
  }

  const renderComponents = () => {
    return (
      <>
        {label && (
          <label className={getLabelClassName()} htmlFor={id}>
            {label}
          </label>
        )}

        <textarea
          id={id}
          className={getTextareaClassName()}
          disabled={disabled}
          rows={rows}
          {...rest}
        />

        {helpText && <p className="mt-2 text-sm text-gray-500 sm:text-xs">{helpText}</p>}
      </>
    )
  }

  if (wrapClassName) {
    return <div className={wrapClassName}>{renderComponents()}</div>
  }

  return renderComponents()
}
