import { nfdUpdatePartial, type UpdatePartialRequestBody } from 'api/api-client'
import { MutationOptions, usePostTransaction } from './usePostTransaction'

export type PatchUpdatePropertiesParams = {
  name: string
  body: UpdatePartialRequestBody
}

export function usePatchUpdateProperties(
  options: MutationOptions<PatchUpdatePropertiesParams> = {}
) {
  return usePostTransaction({
    mutationFn: ({ name, body }) => nfdUpdatePartial(name, body),
    ...options
  })
}
