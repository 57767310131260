import isIPFS from 'is-ipfs'
import { useState, useEffect, useMemo } from 'react'
import { isAlgoXyzUrlValid } from './AlgoXyzUrl.utils'
import { CLIENT_BASE_URL } from 'src/data/constants'
import { AssetSubNav } from 'components/DetailView/Assets/Assets.types'
import type { AlgoXyzUrlProps } from './AlgoXyzUrl'

type UseAlgoXyzComponentArgs = Pick<AlgoXyzUrlProps, 'nfd' | 'url' | 'handleSetUrl'>

export default function useAlgoXyzAddressComponent({
  nfd,
  url,
  handleSetUrl
}: UseAlgoXyzComponentArgs) {
  const [errorMsg, setErrorMsg] = useState('')
  const [viewToggle, setViewToggle] = useState<AssetSubNav | null>(null)

  const assetSubNavUrls = useMemo(() => {
    return {
      [AssetSubNav.Vault]: `${CLIENT_BASE_URL}/name/${nfd.name}?view=assets&show=vault`,
      [AssetSubNav.Owned]: `${CLIENT_BASE_URL}/name/${nfd.name}?view=assets&show=owned`,
      [AssetSubNav.ForSale]: `${CLIENT_BASE_URL}/name/${nfd.name}?view=assets&show=for-sale`,
      [AssetSubNav.Creations]: `${CLIENT_BASE_URL}/name/${nfd.name}?view=assets&show=creations`
    }
  }, [nfd.name])

  useEffect(() => {
    if (!isAlgoXyzUrlValid(url)) {
      const isIpfs = url.startsWith('ipfs://') || isIPFS.urlOrPath(url)
      const msg = isIpfs ? 'Invalid IPFS URL entered' : 'Invalid URL entered'
      setErrorMsg(msg)
    } else {
      setErrorMsg('')
    }

    if (Object.values(assetSubNavUrls).includes(url)) {
      const view = Object.keys(assetSubNavUrls).find(
        (key) => assetSubNavUrls[key as AssetSubNav] === url
      )
      setViewToggle(view as AssetSubNav)
    } else {
      setViewToggle(null)
    }
  }, [assetSubNavUrls, url])

  const handleClear = () => {
    setViewToggle(null)
    handleSetUrl('')
  }

  const handleSetGallery = (view: AssetSubNav) => {
    setViewToggle(view)
    handleSetUrl(`${CLIENT_BASE_URL}/name/${nfd.name}?view=assets${view ? `&show=${view}` : ''}`)
  }

  return {
    errorMsg,
    viewToggle,
    handleClear,
    handleSetGallery,
    isValid: isAlgoXyzUrlValid(url)
  }
}
