import type { NFDProperties, NfdRecord } from 'api/api-client'

export const updateNfdProperties = (nfd: NfdRecord, properties: NFDProperties): NfdRecord => {
  const {
    userDefined: oldUserDefined = {},
    verified: oldVerified = {},
    ...restOfProperties
  } = nfd.properties || {}

  let newUserDefined
  let newVerified

  const updatedUserDefined = {
    ...oldUserDefined,
    ...properties.userDefined
  }

  const filteredUserDefined = Object.fromEntries(
    Object.entries(updatedUserDefined).filter(([_, value]) => value !== '')
  )

  // eslint-disable-next-line prefer-const
  newUserDefined = Object.keys(filteredUserDefined).length > 0 ? filteredUserDefined : undefined

  const updatedVerified = {
    ...oldVerified,
    ...properties.verified
  }

  const filteredVerified = Object.fromEntries(
    Object.entries(updatedVerified).filter(([_, value]) => value !== '')
  )

  // eslint-disable-next-line prefer-const
  newVerified = Object.keys(filteredVerified).length > 0 ? filteredVerified : undefined

  return {
    ...nfd,
    properties: {
      ...restOfProperties,
      ...(newUserDefined ? { userDefined: newUserDefined } : {}),
      ...(newVerified ? { verified: newVerified } : {})
    }
  }
}
