import isIPFS from 'is-ipfs'
import { BiLinkExternal } from 'react-icons/bi'
import ReactLinkify from 'react-linkify'
import { SecureLink } from 'react-secure-link'
import { linkify } from 'lib/linkify'
import { formatLink } from 'helpers/linkify'
import { classNames } from 'helpers/utilities'

interface LinkifyProps {
  children: React.ReactNode
  showIcon?: boolean
  decorate?: boolean
  maxLength?: number
  className?: string
}

export default function Linkify({
  children,
  showIcon = false,
  decorate = false,
  maxLength = 40,
  className = ''
}: LinkifyProps) {
  const matchDecorator = (text: string) => linkify.match(text)
  const textDecorator = (link: string) => (decorate ? formatLink(link, maxLength) : link)

  const getTitle = (href: string) => {
    if (decorate || isIPFS.url(href)) {
      return href
    }
    return ''
  }

  return (
    // @ts-expect-error Not a valid JSX element
    <ReactLinkify
      matchDecorator={matchDecorator}
      textDecorator={textDecorator}
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <SecureLink
          href={decoratedHref}
          key={key}
          className={classNames(
            'inline-flex items-center font-medium text-brand-500 hover:text-brand-600 no-underline whitespace-nowrap group',
            className
          )}
          title={getTitle(decoratedHref)}
        >
          {decoratedText}
          {showIcon && <BiLinkExternal className="mx-1 h-4 w-4 text-gray-400 dark:text-gray-600" />}
        </SecureLink>
      )}
    >
      {children}
    </ReactLinkify>
  )
}
