import Link from 'next/link'
import { HiOutlineInformationCircle } from 'react-icons/hi'
import Input from 'components/Input'
import Textarea from 'components/Textarea'
import type { NfdRecord } from 'api/api-client'
import FieldMenu from './FieldMenu'

export type FieldData = {
  key: string
  value: string
  type: string
  label: string
}

interface FieldProps {
  nfd: NfdRecord
  fieldData: FieldData
  onChange: (key: string, value: string) => void
  onRemove: (key: string) => void
  onReset: (key: string) => void
  isNew: boolean
  isDirty: boolean
}

export default function Field({
  nfd,
  fieldData,
  onChange,
  onRemove,
  onReset,
  isNew,
  isDirty
}: FieldProps) {
  const { key, value, type, label } = fieldData

  return (
    <div>
      <label
        htmlFor={key}
        className="block text-sm font-medium text-gray-700 capitalize dark:text-gray-400"
      >
        {label}
      </label>
      <div className="flex space-x-4">
        {type === 'text' && (
          <Input
            name={key}
            id={key}
            autoComplete={key}
            value={value}
            onChange={(e) => onChange(key, e.target.value)}
            autoFocus={isNew}
          />
        )}
        {type === 'multiline' && (
          <div className="flex-1">
            <Textarea
              name={key}
              id={key}
              value={value}
              onChange={(e) => onChange(key, e.target.value)}
              autoFocus={isNew}
            />
          </div>
        )}
        <div className="pt-1">
          <FieldMenu
            onRemove={() => onRemove(key)}
            onReset={() => onReset(key)}
            isNew={isNew}
            isDirty={isDirty}
          />
        </div>
      </div>
      {['avatar', 'banner'].includes(key) && (
        <div className="sm:flex">
          <div className="rounded-md bg-gray-50 p-4 pr-12 mt-3">
            <div className="flex">
              <div className="flex-shrink-0">
                <HiOutlineInformationCircle className="h-5 w-5 text-brand-500" aria-hidden="true" />
              </div>
              <div className="ml-3 flex-1 text-sm font-medium text-gray-700">
                You can upload a new {key} in the{' '}
                <Link
                  href={`/manage/${nfd.name}?view=profile`}
                  className="font-semibold underline text-gray-700 rounded-sm hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
                >
                  Profile
                </Link>{' '}
                section
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
