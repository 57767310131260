import Button from 'components/Button'
import { classNames } from 'helpers/utilities'

interface TriggerProps {
  type: 'button' | 'link' | 'jsx'
  className?: string
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  children: React.ReactNode
}

export default function Trigger({
  type,
  className = '',
  setIsOpen,
  children,
  ...props
}: TriggerProps) {
  if (type === 'button') {
    return (
      <Button className={className} onClick={() => setIsOpen(true)} {...props}>
        {children}
      </Button>
    )
  }

  if (type === 'link') {
    return (
      <a
        role="button"
        className={classNames(
          'text-gray-900 font-semibold whitespace-nowrap hover:text-brand-500 dark:text-brand-500 dark:hover:text-brand-600',
          className
        )}
        onClick={() => setIsOpen(true)}
        {...props}
      >
        {children}
      </a>
    )
  }

  return (
    <a
      role="button"
      className={classNames('whitespace-nowrap', className)}
      onClick={() => setIsOpen(true)}
      {...props}
    >
      {children}
    </a>
  )
}
