import { NfdRecord } from 'api/api-client'

export const isBannerVerified = (nfd: NfdRecord): boolean => {
  return !!nfd?.properties?.verified?.banner && !nfd?.properties?.userDefined?.banner
}

export const getBannerURL = (nfd: NfdRecord) => {
  const url = nfd?.properties?.userDefined?.banner || nfd?.properties?.verified?.banner

  if (!url) {
    return `/img/nfd-banner-placeholder.jpg`
  }

  return url
}
