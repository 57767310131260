import dynamic from 'next/dynamic'
import { useState } from 'react'
import { HiOutlineInformationCircle } from 'react-icons/hi'
import Tooltip from 'components/Tooltip'
import Trigger from './Trigger'
import type { IconType } from 'react-icons'

const Modal = dynamic(() => import('./Modal'))

type TriggerTooltip = {
  text: string
  direction?: 'top' | 'bottom' | 'left' | 'right'
  distance?: number
  className?: string
}

interface SimpleModalProps {
  triggerType?: 'button' | 'link' | 'jsx'
  triggerText: string | JSX.Element | JSX.Element[]
  triggerClassName?: string
  tooltip?: string | TriggerTooltip
  title: string
  icon?: IconType
  content: React.ReactNode
  className?: string
}

export default function SimpleModal({
  triggerType = 'link',
  triggerText,
  triggerClassName = '',
  tooltip,
  title,
  icon = HiOutlineInformationCircle,
  content,
  className = ''
}: SimpleModalProps) {
  const [isOpen, setIsOpen] = useState(false)

  const renderTriggerText = () => {
    if (!tooltip) {
      return triggerText
    }

    const {
      text,
      direction = 'top',
      distance = 16,
      className: tooltipClassName = ''
    } = typeof tooltip === 'string' ? { text: tooltip } : tooltip

    return (
      <Tooltip
        as="span"
        text={text}
        direction={direction}
        distance={distance}
        className={tooltipClassName}
      >
        <span>{triggerText}</span>
      </Tooltip>
    )
  }

  return (
    <>
      <Trigger type={triggerType} setIsOpen={setIsOpen} className={triggerClassName}>
        {renderTriggerText()}
      </Trigger>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} title={title} className={className} icon={icon}>
        <div className="text-sm text-gray-600 dark:text-gray-500">{content}</div>
      </Modal>
    </>
  )
}
