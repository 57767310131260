import { BiLinkExternal } from 'react-icons/bi'
import Button from 'components/Button'
import { truncateAddress } from 'src/helpers/utilities'
import type { ToastProps } from 'api/hooks/usePostTransaction'

export default function TransactionSuccess({ data, explorerLink }: ToastProps) {
  const txnId = data?.txId

  return (
    <>
      <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        Transaction successful
      </h3>
      {txnId && (
        <div className="my-2 max-w-[75vw] text-sm text-gray-500 break-words">
          <span className="text-gray-900 font-medium dark:text-gray-400">Transaction ID:</span>{' '}
          <span className="block font-mono w-80 mx-auto sm:mx-0 sm:hidden">
            {truncateAddress(txnId)}
          </span>
          <span className="hidden font-mono w-80 mx-auto sm:mx-0 sm:block">{txnId}</span>
        </div>
      )}
      <div className="mt-3 flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
        {explorerLink && (
          <a href={explorerLink} target="_blank" rel="noreferrer">
            <Button icon={BiLinkExternal}>View Transaction</Button>
          </a>
        )}
      </div>
    </>
  )
}
