import { classNames } from 'src/helpers/utilities'
import useId from 'hooks/useId'

const BASE_CLASSES = {
  label: 'block text-sm font-medium leading-5 text-gray-700 dark:text-gray-400',
  input:
    'mt-1 shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-100 dark:border-transparent dark:focus:border-brand-500 dark:focus:ring-brand-500 dark:caret-gray-400'
}

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type?:
    | 'text'
    | 'number'
    | 'password'
    | 'email'
    | 'tel'
    | 'url'
    | 'search'
    | 'date'
    | 'time'
    | 'datetime-local'
    | 'month'
    | 'week'
    | 'color'
  id?: string
  className?: string
  disabled?: boolean
  label?: string
  labelClassName?: string
  wrapClassName?: string
  helpText?: string
}

export default function Input(props: InputProps) {
  const {
    type = 'text',
    id: idOverride,
    className,
    disabled,
    label,
    labelClassName,
    wrapClassName,
    helpText,
    ...rest
  } = props

  const id = useId(idOverride)

  const getLabelClassName = () => {
    return classNames(BASE_CLASSES.label, labelClassName as string)
  }

  const getInputClassName = () => {
    return classNames(BASE_CLASSES.input, className as string)
  }

  const renderComponents = () => {
    return (
      <>
        {label && (
          <label className={getLabelClassName()} htmlFor={id}>
            {label}
          </label>
        )}

        <input type={type} id={id} className={getInputClassName()} disabled={disabled} {...rest} />

        {helpText && <p className="mt-2 text-sm text-gray-500">{helpText}</p>}
      </>
    )
  }

  if (wrapClassName) {
    return <div className={wrapClassName}>{renderComponents()}</div>
  }

  return renderComponents()
}
