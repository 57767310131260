import { useEffect, useMemo, useRef, useState } from 'react'
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'
import { classNames } from 'helpers/utilities'

interface ScrollableTabsProps {
  children: React.ReactNode
}

export default function ScrollableTabs({ children }: ScrollableTabsProps) {
  const [scrollWidth, setScrollWidth] = useState(0)
  const [viewportWidth, setViewportWidth] = useState(0)
  const [scrollPosition, setScrollPosition] = useState(0)

  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleResize = () => {
      if (scrollRef.current) {
        const scroll = scrollRef.current
        const viewportWidth = window.innerWidth || document.documentElement.clientWidth
        const scrollWidth = scroll.scrollWidth
        setViewportWidth(viewportWidth)
        setScrollWidth(scrollWidth)
      }
    }
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    setScrollPosition(Math.ceil(e.currentTarget.scrollLeft))
  }

  const showScrollLeft = useMemo(() => {
    return scrollWidth > viewportWidth && scrollPosition > 0
  }, [viewportWidth, scrollPosition, scrollWidth])

  const showScrollRight = useMemo(() => {
    return scrollWidth > viewportWidth && scrollPosition < scrollWidth - viewportWidth
  }, [viewportWidth, scrollPosition, scrollWidth])

  const isScrollable = useMemo(() => {
    return scrollWidth >= viewportWidth
  }, [scrollWidth, viewportWidth])

  const handleScrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: Math.max(0, scrollPosition - viewportWidth / 2),
        behavior: 'smooth'
      })
    }
  }

  const handleScrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: Math.min(scrollWidth - viewportWidth, scrollPosition + viewportWidth / 2),
        behavior: 'smooth'
      })
    }
  }

  return (
    <div className="relative">
      <div
        ref={scrollRef}
        onScroll={handleScroll}
        className={classNames(isScrollable ? 'overflow-x-auto hide-scrollbar' : '')}
      >
        {children}
      </div>

      {showScrollLeft && (
        <div className="absolute left-0 inset-y-0 px-2 flex items-center justify-center bg-white dark:bg-gray-900">
          <button
            type="button"
            className="inline-flex items-center justify-center w-8 h-8 border border-transparent rounded-full hover:bg-gray-50 text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 dark:text-gray-300 dark:hover:bg-gray-400/10 dark:focus:ring-offset-gray-900"
            onClick={handleScrollLeft}
            aria-label="Scroll left"
          >
            <HiChevronLeft className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      )}

      {showScrollRight && (
        <div className="absolute right-0 inset-y-0 px-2 flex items-center justify-center bg-white dark:bg-gray-900">
          <button
            type="button"
            className="inline-flex items-center justify-center w-8 h-8 border border-transparent rounded-full hover:bg-gray-50 text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 dark:text-gray-300 dark:hover:bg-gray-400/10 dark:focus:ring-offset-gray-900"
            onClick={handleScrollRight}
            aria-label="Scroll right"
          >
            <HiChevronRight className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      )}
    </div>
  )
}
