import type { NfdRecord } from 'api/api-client'
import type { BadgeType, NfdPreferences } from 'types/api'

const DEFAULT_PREFERENCES: NfdPreferences = { hideBadges: [] }

export const initPreferences = (nfd: NfdRecord): NfdPreferences => {
  let preferences = DEFAULT_PREFERENCES

  if (nfd.properties?.userDefined?.prefs) {
    preferences = { ...preferences, ...JSON.parse(nfd.properties?.userDefined.prefs) }
  }

  if (preferences.hideBadges.length) {
    preferences.hideBadges.sort()
  }

  return preferences
}

export default function useNfdPreferences(nfd: NfdRecord) {
  const preferences = initPreferences(nfd)

  const checkIsBadgeEnabled = (badge: BadgeType) => {
    return preferences.hideBadges.includes(badge) ? false : true
  }

  return {
    preferences,
    checkIsBadgeEnabled
  }
}
