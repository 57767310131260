import { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import { useInView } from 'react-intersection-observer'
import { HiOutlinePlusSm, HiArrowDown } from 'react-icons/hi'
import Button from 'components/Button'
import Textarea from 'components/Textarea'
import Tooltip from 'components/Tooltip'
import TransactionSuccess from 'components/toasts/TransactionSuccess'
import AlgoXyzUrl, { isAlgoXyzUrlValid } from 'components/manage/AlgoXyzUrl'
import { ManageAvatarBanner } from 'components/manage/AvatarBanner/ManageAvatarBanner'
import Field from 'components/manage/metadata/Field'
import VerificationHelpText from 'components/manage/metadata/VerificationHelpText'
import VerifiedBadge from 'components/manage/metadata/VerifiedBadge'
import { useNameUpdate } from 'api/hooks/useName'
import { usePutUpdateProperties } from 'api/hooks/usePutUpdateProperties'
import useErrorToast from 'hooks/useErrorToast'
import useUnsavedChanges from 'hooks/useUnsavedChanges'
import { updateNfdProperties } from 'helpers/optimisticUpdates'
import { classNames, formatUsername } from 'helpers/utilities'
import type { NFDPropertiesUserDefined, NfdRecord } from 'api/api-client'

export const predefinedFields = [
  'avatar',
  'banner',
  'bio',
  'name',
  'email',
  'address',
  'twitter',
  'discord',
  'telegram',
  'linkedin',
  'url',
  'domain',
  'github',
  'website',
  'caalgo',
  'prefs'
]

interface ManageMetadataProps {
  nfd: NfdRecord
  walletAddress: string | undefined
  refetch: () => void
}

export default function ManageMetadata({ nfd: _nfd, walletAddress, refetch }: ManageMetadataProps) {
  const [nfd, setNfd] = useState<NfdRecord>(_nfd)

  const handleError = useErrorToast()

  useEffect(() => {
    if (_nfd) setNfd(_nfd)
  }, [_nfd])

  const isDirty = useMemo(() => {
    const _userDefined = _nfd.properties?.userDefined || {}

    const userDefined: NFDPropertiesUserDefined = nfd.properties?.userDefined || {}

    if (Object.keys(_userDefined).length !== Object.keys(userDefined).length) return true

    return Object.keys(userDefined).some((key) => {
      const value = userDefined[key]
      const originalValue = _userDefined[key]

      return value !== originalValue
    })
  }, [_nfd, nfd])

  useUnsavedChanges(isDirty)

  const isValid = useMemo(() => {
    return isAlgoXyzUrlValid(nfd.properties?.userDefined?.url)
  }, [nfd.properties?.userDefined?.url])

  const submitRef = useRef<null | HTMLDivElement>()
  const buttonRef = useRef<null | HTMLButtonElement>(null)

  const [isScrolling, setIsScrolling] = useState(false)

  const [inViewRef, submitInView] = useInView({
    threshold: 0.5
  })

  const setSubmitRef = useCallback(
    (node: HTMLDivElement) => {
      submitRef.current = node
      inViewRef(node)
    },
    [inViewRef]
  )

  const handleScrollToBottom = () => {
    if (submitRef.current && !submitInView && !isScrolling) {
      setIsScrolling(true)
      submitRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    if (isScrolling && submitInView) {
      setTimeout(() => {
        buttonRef?.current?.focus()
        setIsScrolling(false)
      }, 250)
    }
  }, [isScrolling, submitInView])

  const [customField, setCustomField] = useState('')

  const optimisticUpdate = useNameUpdate()

  const { mutateAsync: updateProperties } = usePutUpdateProperties({
    toasts: {
      success: TransactionSuccess
    },
    onSuccess(data, params) {
      if (!params.body.properties) return
      const newNfd = updateNfdProperties(nfd, params.body.properties)
      optimisticUpdate(newNfd)
    }
  })

  const hasVerifiedField = (key: string) => {
    const verified = _nfd.properties?.verified || {}
    return !!verified[key] === true
  }

  const handlePropertyChange = (key: string, value: string) => {
    setNfd({
      ...nfd,
      properties: {
        ...(nfd.properties || {}),
        userDefined: {
          ...(nfd.properties?.userDefined || {}),
          [key]: value
        }
      }
    })
  }

  const handleRemoveField = (key: string) => {
    const { [key]: _, ...userDefined } = nfd.properties?.userDefined || {}

    setNfd({
      ...nfd,
      properties: {
        ...(nfd.properties || {}),
        userDefined
      }
    })
  }

  const handleAddCustomField = () => {
    if (customField) {
      setNfd({
        ...nfd,
        properties: {
          ...(nfd.properties || {}),
          userDefined: {
            [customField]: '',
            ...(nfd.properties?.userDefined || {})
          }
        }
      })

      setCustomField('')
    }
  }

  const handleCustomFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target

    // matches letters only (case insensitive) up to 30 characters
    const regExp = /^[A-Za-z]{0,30}$/gm

    if (value !== '' && value.match(regExp) === null) {
      return
    }

    // set any capital letters to lowercase
    setCustomField(value.toLowerCase())
  }

  const isNewField = (key: string) => {
    return !Object.keys(_nfd.properties?.userDefined || {}).includes(key)
  }

  const isFieldDirty = (key: string) => {
    const value = nfd.properties?.userDefined?.[key]
    const originalValue = _nfd.properties?.userDefined?.[key]
    return value !== originalValue
  }

  const handleResetField = (key: string) => {
    if (_nfd.properties?.userDefined?.[key] === undefined) return
    handlePropertyChange(key, _nfd.properties.userDefined[key])
  }

  const handleResetAll = () => {
    setNfd(_nfd)
  }

  const handleSave = async () => {
    try {
      const body = {
        sender: walletAddress as string,
        properties: {
          userDefined: nfd.properties?.userDefined
        }
      }

      await updateProperties({ name: nfd.name, body })

      refetch()
    } catch (e) {
      handleError(e)
    }
  }

  const verifiedInputClassname =
    'focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md read-only:bg-gray-100 read-only:focus:ring-gray-500 read-only:focus:border-gray-500 dark:bg-gray-800 dark:text-gray-100 dark:border-transparent dark:focus:border-brand-500 dark:focus:ring-brand-500 dark:caret-gray-400 dark:read-only:bg-gray-850/75 dark:read-only:focus:ring-gray-500 dark:read-only:focus:border-gray-500'

  const renderCustomFields = () => {
    const fields = Object.keys(nfd.properties?.userDefined ?? {})
      .filter((key) => !predefinedFields.includes(key) && !key.startsWith('ca.'))
      .map((key) => {
        return {
          key,
          value: nfd.properties?.userDefined?.[key] || '',
          type: 'text',
          label: key.replace(/^\w/, (c) => c.toUpperCase()) // capitalize first letter
        }
      })

    return fields.map((field) => (
      <div key={field.key}>
        <Field
          nfd={nfd}
          fieldData={field}
          onChange={handlePropertyChange}
          onRemove={handleRemoveField}
          onReset={handleResetField}
          isNew={isNewField(field.key)}
          isDirty={isFieldDirty(field.key)}
        />
      </div>
    ))
  }

  return (
    <>
      <div className="space-y-8 divide-y divide-gray-200 dark:divide-gray-750/75">
        <div className="py-6 px-4 sm:p-6 lg:pb-8 space-y-6">
          <div className="space-y-8 divide-y divide-gray-200 dark:divide-gray-750/75">
            <div>
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                  Profile
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Your avatar, banner and bio will be displayed at the top of your NFD profile
                </p>
              </div>

              <div className="mt-6 space-y-6">
                <ManageAvatarBanner nfd={nfd} />

                <div className="max-w-xl">
                  <Textarea
                    name="bio"
                    id="bio"
                    rows={3}
                    maxLength={512}
                    value={nfd.properties?.userDefined?.bio || ''}
                    onChange={(e) => handlePropertyChange('bio', e.target.value)}
                    label="Bio"
                    helpText="Brief description for your profile. Max 512 characters"
                  />
                </div>
              </div>
            </div>

            <div className="pt-8">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                  Personal Information
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  This information will be displayed publicly so be careful what you share
                </p>
              </div>
              <div className="mt-6 space-y-6">
                <div className="max-w-xl">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                  >
                    Name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-100 dark:border-transparent dark:focus:border-brand-500 dark:focus:ring-brand-500 dark:caret-gray-400"
                      value={nfd.properties?.userDefined?.name || ''}
                      onChange={(e) => handlePropertyChange('name', e.target.value)}
                    />
                  </div>
                </div>

                <div className="max-w-xl">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                  >
                    Email address
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className={classNames(
                        hasVerifiedField('email') ? 'pr-10' : '',
                        verifiedInputClassname
                      )}
                      value={
                        _nfd.properties?.verified?.email || nfd.properties?.userDefined?.email || ''
                      }
                      onChange={(e) => handlePropertyChange('email', e.target.value)}
                      readOnly={hasVerifiedField('email')}
                    />
                    <VerifiedBadge isVerified={hasVerifiedField('email')} />
                  </div>
                  <VerificationHelpText nfd={_nfd} field="email" label="email account" />
                </div>

                <div className="max-w-xl">
                  <Textarea
                    name="address"
                    id="address"
                    rows={3}
                    maxLength={512}
                    value={nfd.properties?.userDefined?.address || ''}
                    onChange={(e) => handlePropertyChange('address', e.target.value)}
                    label="Address"
                  />
                </div>
              </div>
            </div>

            <div className="pt-8">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                  Social Media
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Accounts that you add can then be verified
                </p>
              </div>
              <div className="mt-6 space-y-6">
                <div className="max-w-xl">
                  <label
                    htmlFor="twitter"
                    className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                  >
                    Twitter
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span className="text-gray-500 sm:text-sm dark:text-gray-400">@</span>
                    </div>
                    <input
                      type="text"
                      name="twitter"
                      id="twitter"
                      className={classNames(
                        hasVerifiedField('twitter') ? 'pr-10' : '',
                        verifiedInputClassname,
                        'pl-7'
                      )}
                      value={
                        formatUsername(_nfd.properties?.verified?.twitter, true) ||
                        nfd.properties?.userDefined?.twitter ||
                        ''
                      }
                      onChange={(e) => handlePropertyChange('twitter', e.target.value)}
                      readOnly={hasVerifiedField('twitter')}
                    />
                    <VerifiedBadge isVerified={hasVerifiedField('twitter')} />
                  </div>
                  <VerificationHelpText nfd={_nfd} field="twitter" label="Twitter account" />
                </div>

                <div className="max-w-xl">
                  <label
                    htmlFor="discord"
                    className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                  >
                    Discord
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      id="discord"
                      name="discord"
                      type="text"
                      className={classNames(
                        hasVerifiedField('discord') ? 'pr-10' : '',
                        verifiedInputClassname
                      )}
                      value={
                        _nfd.properties?.verified?.discord ||
                        nfd.properties?.userDefined?.discord ||
                        ''
                      }
                      onChange={(e) => handlePropertyChange('discord', e.target.value)}
                      readOnly={hasVerifiedField('discord')}
                    />
                    <VerifiedBadge isVerified={hasVerifiedField('discord')} />
                  </div>
                  <VerificationHelpText nfd={_nfd} field="discord" label="Discord account" />
                </div>

                <div className="max-w-xl">
                  <label
                    htmlFor="telegram"
                    className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                  >
                    Telegram
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      id="telegram"
                      name="telegram"
                      type="text"
                      className={classNames(
                        hasVerifiedField('telegram') ? 'pr-10' : '',
                        verifiedInputClassname
                      )}
                      value={
                        _nfd.properties?.verified?.telegram ||
                        nfd.properties?.userDefined?.telegram ||
                        ''
                      }
                      onChange={(e) => handlePropertyChange('telegram', e.target.value)}
                      readOnly={hasVerifiedField('telegram')}
                    />
                    <VerifiedBadge isVerified={hasVerifiedField('telegram')} />
                  </div>
                  <VerificationHelpText nfd={_nfd} field="telegram" label="Telegram account" />
                </div>

                <div className="max-w-xl">
                  <label
                    htmlFor="github"
                    className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                  >
                    GitHub
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      id="github"
                      name="github"
                      type="text"
                      className={classNames(
                        hasVerifiedField('github') ? 'pr-10' : '',
                        verifiedInputClassname
                      )}
                      value={
                        _nfd.properties?.verified?.github ||
                        nfd.properties?.userDefined?.github ||
                        ''
                      }
                      onChange={(e) => handlePropertyChange('github', e.target.value)}
                      readOnly={hasVerifiedField('github')}
                    />
                    <VerifiedBadge isVerified={hasVerifiedField('github')} />
                  </div>
                  <VerificationHelpText nfd={_nfd} field="github" label="GitHub account" />
                </div>

                <div className="max-w-xl">
                  <label
                    htmlFor="linkedin"
                    className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                  >
                    LinkedIn
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="linkedin"
                      id="linkedin"
                      className="shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-100 dark:border-transparent dark:focus:border-brand-500 dark:focus:ring-brand-500 dark:caret-gray-400"
                      value={nfd.properties?.userDefined?.linkedin || ''}
                      onChange={(e) => handlePropertyChange('linkedin', e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-8">
              <AlgoXyzUrl
                nfd={nfd}
                url={nfd.properties?.userDefined?.url || ''}
                handleSetUrl={(url) => handlePropertyChange('url', url)}
              />
            </div>

            <div className="pt-8">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                  Websites
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Links to personal/business websites associated with this NFD
                </p>
              </div>
              <div className="mt-6 space-y-6">
                <div className="max-w-xl">
                  <label
                    htmlFor="domain"
                    className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                  >
                    Domain
                  </label>
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <input
                      id="domain"
                      name="domain"
                      type="text"
                      className={classNames(
                        hasVerifiedField('domain') ? 'pr-10' : '',
                        verifiedInputClassname
                      )}
                      value={
                        _nfd.properties?.verified?.domain ||
                        nfd.properties?.userDefined?.domain ||
                        ''
                      }
                      onChange={(e) => handlePropertyChange('domain', e.target.value)}
                      placeholder='e.g. "example.com"'
                      readOnly={hasVerifiedField('domain')}
                    />
                    <VerifiedBadge isVerified={hasVerifiedField('domain')} />
                  </div>
                  <VerificationHelpText nfd={_nfd} field="domain" label="domain" />
                </div>

                <div className="max-w-xl">
                  <label
                    htmlFor="website"
                    className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                  >
                    Website
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="website"
                      id="website"
                      className="shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-100 dark:border-transparent dark:focus:border-brand-500 dark:focus:ring-brand-500 dark:caret-gray-400"
                      value={nfd.properties?.userDefined?.website || ''}
                      onChange={(e) => handlePropertyChange('website', e.target.value)}
                      placeholder={`e.g. "https://example.com"`}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-8">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                  Custom Fields
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Add any custom fields you want to associate with this NFD
                </p>
              </div>

              <div className="mt-6">
                <div className="max-w-xl">
                  <div className="bg-gray-50 sm:rounded-lg dark:bg-gray-850/75">
                    <div className="px-4 py-5 sm:p-6">
                      <label
                        htmlFor="addCustomField"
                        className="block text-sm font-medium text-gray-700 dark:text-gray-400"
                      >
                        Field name
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <div className="relative flex items-stretch flex-grow focus-within:z-10">
                          <input
                            type="text"
                            name="addCustomField"
                            id="addCustomField"
                            className="focus:ring-brand-500 focus:border-brand-500 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300 dark:bg-gray-750/75 dark:text-gray-100 dark:border-transparent dark:focus:border-brand-500 dark:focus:ring-brand-500 dark:caret-gray-400"
                            placeholder={`e.g. "birthday"`}
                            value={customField}
                            onChange={handleCustomFieldChange}
                            maxLength={30}
                            aria-describedby="custom-description"
                          />
                        </div>
                        <button
                          type="button"
                          className="-ml-px relative inline-flex items-center sm:space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-100 hover:bg-gray-200/50 focus:outline-none focus:ring-1 focus:ring-brand-500 focus:border-brand-500 disabled:opacity-50 disabled:bg-gray-50 disabled:text-gray-700 dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-gray-300 dark:border-transparent dark:focus:border-brand-500 dark:focus:ring-brand-500 dark:disabled:opacity-25 dark:disabled:bg-gray-700 dark:ml-0"
                          onClick={handleAddCustomField}
                          disabled={customField.length === 0}
                        >
                          <HiOutlinePlusSm
                            className="hidden sm:inline xs:-ml-1 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="whitespace-nowrap">
                            Add <span className="hidden xs:inline">field</span>
                          </span>
                        </button>
                      </div>
                      <p className="mt-2 text-sm sm:text-xs text-gray-500" id="custom-description">
                        Letters only (no spaces), 30 characters max
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-6 space-y-6">{renderCustomFields()}</div>
            </div>
          </div>
        </div>
        <div ref={setSubmitRef} className="mt-4 py-4 px-4 flex justify-end sm:px-6 space-x-4">
          {isDirty && (
            <Button size="lg" onClick={handleResetAll}>
              Revert form
            </Button>
          )}
          <Button
            ref={buttonRef}
            type="submit"
            variant="gradient"
            size="lg"
            onClick={handleSave}
            disabled={!isDirty || !isValid}
          >
            Update metadata
          </Button>
        </div>
      </div>

      {isDirty && isValid && !submitInView && (
        <div className="fixed bottom-0 right-0 px-6 py-4 sm:px-12 lg:px-14 2xl:px-6">
          <Tooltip text="Scroll down to submit" direction="left" className="-mt-3">
            <button
              type="button"
              className="animate-bounce inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 dark:focus:ring-offset-gray-900"
              onClick={handleScrollToBottom}
            >
              <HiArrowDown className="h-6 w-6" aria-hidden="true" />
            </button>
          </Tooltip>
        </div>
      )}
    </>
  )
}
