import { Menu, Transition } from '@headlessui/react'
import { useWallet } from '@txnlab/use-wallet-react'
import * as React from 'react'
import toast from 'react-hot-toast'
// import { HiChevronDown } from 'react-icons/hi'
import { NfdRecord } from '@/api/api-client'
import { useNameUpdate } from '@/api/hooks/useName'
import { usePatchUpdateProperties } from '@/api/hooks/usePatchUpdateProperties'
import { Field } from '@/components/manage/AvatarBanner/ManageAvatarBanner'
import { updateNfdProperties } from '@/helpers/optimisticUpdates'
import { capitalizeFirstLetter } from '@/helpers/strings'
import { clsxMerge } from '@/helpers/utilities'
import useErrorToast from '@/hooks/useErrorToast'

const MAX_FILE_SIZE = 5 * 1024 * 1024 // 5MB in bytes

interface AvatarBannerMenuProps {
  nfd: NfdRecord
  field: Field
  setField: (field: Field | null) => void
}

export default function AvatarBannerMenu({ nfd, field, setField }: AvatarBannerMenuProps) {
  const { inputRef, handleSubmitFile, isUploading } = useUploadFile(nfd, field)
  const { handleResetField, disableReset } = useResetField(nfd, field)

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div className="flex items-center h-10">
          <Menu.Button className="inline-flex w-full items-center gap-x-1.5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 dark:bg-gray-750 dark:border-transparent dark:text-gray-300 dark:hover:bg-gray-700 dark:focus:ring-offset-gray-900 dark:focus:ring-brand-500">
            Update
            {/* <HiChevronDown
            className="-mr-1 h-5 w-5 text-gray-400 dark:text-white/50"
            aria-hidden="true"
          /> */}
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-[22] overflow-hidden origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none dark:bg-gray-850 dark:text-gray-400 dark:ring-gray-800/40 dark:divide-gray-800">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={clsxMerge(
                      active
                        ? 'bg-gray-100 text-gray-900 dark:bg-gray-950 dark:text-gray-100'
                        : 'text-gray-700 dark:text-gray-400',
                      'block w-full text-left px-4 py-2 text-sm cursor-pointer',
                      isUploading && 'opacity-50 cursor-not-allowed'
                    )}
                    onClick={() => {
                      inputRef.current?.click()
                    }}
                    disabled={isUploading}
                  >
                    {isUploading
                      ? 'Uploading...'
                      : `Upload image (${MAX_FILE_SIZE / 1024 / 1024}MB max)`}
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={clsxMerge(
                      active
                        ? 'bg-gray-100 text-gray-900 dark:bg-gray-950 dark:text-gray-100'
                        : 'text-gray-700 dark:text-gray-400',
                      'block w-full text-left px-4 py-2 text-sm'
                    )}
                    onClick={() => setField(field)}
                  >
                    Select an NFT
                  </button>
                )}
              </Menu.Item>
            </div>
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={clsxMerge(
                      active
                        ? 'bg-gray-100 text-gray-900 dark:bg-gray-950 dark:text-gray-100'
                        : 'text-gray-700 dark:text-gray-400',
                      'block w-full text-left px-4 py-2 text-sm disabled:opacity-50'
                    )}
                    onClick={handleResetField}
                    disabled={disableReset}
                  >
                    Reset to default
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <form className="hidden">
        <input
          ref={inputRef}
          id="file-upload"
          name="file-upload"
          type="file"
          onChange={handleSubmitFile}
          accept="image/jpeg,image/png,image/gif"
          disabled={isUploading}
        />
      </form>
    </>
  )
}

function useUploadFile(nfd: NfdRecord, field: 'avatar' | 'banner', onComplete?: () => void) {
  const [isUploading, setIsUploading] = React.useState(false)
  const { activeAddress } = useWallet()
  const optimisticUpdate = useNameUpdate()

  const { mutateAsync: updateFileUrl } = usePatchUpdateProperties({
    toasts: {
      success: `${capitalizeFirstLetter(field)} successfully updated!`
    },
    onSuccess(data, params) {
      if (!params.body.properties) return
      const newNfd = updateNfdProperties(nfd, params.body.properties)
      optimisticUpdate(newNfd)
    }
  })

  const inputRef = React.useRef<HTMLInputElement>(null)

  const handleError = (errorMessage: string, toastId?: string) => {
    console.error('Upload error:', errorMessage)
    toast.error(errorMessage, {
      id: toastId
    })
    if (inputRef.current) {
      inputRef.current.value = ''
    }
    setIsUploading(false)
  }

  const handleSubmitFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const file = event.target.files?.[0]

    if (!file) {
      handleError('No file selected.')
      return
    }

    if (!file.type.match(/^image\/(jpeg|png|gif)$/)) {
      handleError('Uploaded image must be JPG, PNG, or GIF format. Please select another image.')
      return
    }

    if (file.size > MAX_FILE_SIZE) {
      handleError(
        `Uploaded image must be less than ${
          MAX_FILE_SIZE / 1024 / 1024
        } MB. Please select another image.`
      )
      return
    }

    setIsUploading(true)
    const toastId = toast.loading(`Uploading ${field}`)

    try {
      if (!activeAddress) {
        throw new Error('No active address')
      }

      const request = new Request(
        `${process.env.NEXT_PUBLIC_API}/nfd/updateImage/${nfd.name}/${nfd.owner}/${field}?wantTxn=false`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/octet-stream' },
          body: file
        }
      )

      const response = await fetch(request)

      if (!response.ok) {
        throw new Error(response.statusText)
      }

      const url = await response.json()

      await updateFileUrl({
        name: nfd.name,
        body: {
          sender: activeAddress,
          properties: {
            userDefined: { [field]: url }
          }
        }
      })

      toast.dismiss(toastId)
      toast.success(`${capitalizeFirstLetter(field)} successfully updated!`)

      if (inputRef.current) {
        inputRef.current.value = ''
      }
      onComplete?.()
    } catch (error) {
      console.error('Upload error:', error)
      const errorMessage = error instanceof Error ? error.message : 'Unknown error'
      handleError(`Error uploading ${field}: ${errorMessage}`, toastId)
    } finally {
      setIsUploading(false)
    }
  }

  return { handleSubmitFile, inputRef, isUploading }
}

function useResetField(nfd: NfdRecord, field: Field) {
  const optimisticUpdate = useNameUpdate()
  const handleError = useErrorToast()
  const { activeAddress } = useWallet()

  const resetField = usePatchUpdateProperties({
    onSuccess(data, params) {
      if (!params.body.properties) return
      const newNfd = updateNfdProperties(nfd, params.body.properties)
      optimisticUpdate(newNfd)
    },
    onError(error) {
      handleError(error)
    },
    toasts: {
      success: `${capitalizeFirstLetter(field)} reset to default`
    }
  })

  const isUserDefinedDefault = !nfd.properties?.userDefined?.[field]
  const isVerifiedDefault = !nfd.properties?.verified?.[field]
  const disableReset = isUserDefinedDefault && isVerifiedDefault

  const handleResetField = () => {
    let properties = {}

    if (!isUserDefinedDefault) {
      properties = {
        userDefined: {
          [field]: ''
        }
      }
    }

    if (!isVerifiedDefault) {
      properties = {
        ...properties,
        verified: {
          [field]: ''
        }
      }
    }

    const payload = {
      name: nfd.name,
      body: {
        sender: activeAddress || '',
        properties
      }
    }

    resetField.mutate(payload)
  }

  return { handleResetField, disableReset }
}
