import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { HiDotsHorizontal, HiTrash, HiReply } from 'react-icons/hi'
import { classNames } from 'helpers/utilities'

interface FieldMenuProps {
  onRemove: () => void
  onReset: () => void
  isNew: boolean
  isDirty: boolean
}

export default function FieldMenu({ onRemove, onReset, isNew, isDirty }: FieldMenuProps) {
  const handleReset = () => {
    // wait 100ms for menu close transition before resetting
    setTimeout(() => {
      onReset()
    }, 100)
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div className="flex items-center h-10">
        <Menu.Button className="bg-white rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-brand-500 dark:bg-transparent dark:text-gray-500 dark:hover:text-gray-300 dark:focus:ring-offset-gray-900 dark:focus:text-gray-300">
          <span className="sr-only">Open menu</span>
          <HiDotsHorizontal className="h-6 w-6 sm:h-5 sm:w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-800 dark:text-gray-400 dark:ring-gray-800/40 dark:divide-gray-750">
          <div className="py-1">
            {!isNew && isDirty && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={classNames(
                      active
                        ? 'bg-gray-100 text-gray-900 dark:bg-gray-900/50 dark:text-gray-200'
                        : 'text-gray-700 dark:text-gray-400',
                      'group flex items-center w-full px-4 py-2 sm:text-sm'
                    )}
                    onClick={handleReset}
                  >
                    <HiReply
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Revert changes
                  </button>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  className={classNames(
                    active
                      ? 'bg-gray-100 text-gray-900 dark:bg-gray-900/50 dark:text-gray-200'
                      : 'text-gray-700 dark:text-gray-400',
                    'group flex items-center w-full px-4 py-2 sm:text-sm'
                  )}
                  onClick={onRemove}
                >
                  <HiTrash
                    className="mr-3 h-5 w-5 text-red-500 lg:text-gray-400 group-hover:text-red-500 dark:text-red-500"
                    aria-hidden="true"
                  />
                  Remove
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
