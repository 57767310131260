import dynamic from 'next/dynamic'
import { useRef } from 'react'
import { HiOutlineInformationCircle } from 'react-icons/hi'
import Button from 'components/Button'
import type { IconType } from 'react-icons'

const ModalComponent = dynamic(() => import('components/Modal'))

interface ModalProps {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  title: string
  icon?: IconType
  className?: string
  children: React.ReactNode
}

export default function Modal({
  isOpen,
  setIsOpen,
  title,
  icon = HiOutlineInformationCircle,
  className = '',
  children
}: ModalProps) {
  const closeButtonRef = useRef<HTMLButtonElement>(null)

  return (
    <ModalComponent
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={title}
      icon={icon}
      initialFocus={closeButtonRef}
      showX={false}
      className={className}
    >
      {children}
      <div className="space-x-2 mt-6 flex justify-end">
        <Button ref={closeButtonRef} onClick={() => setIsOpen(false)}>
          Close
        </Button>
      </div>
    </ModalComponent>
  )
}
